<template>
  <div class="layout--default">
    <Notification
      v-for="(noti,index) in notification.msg"
      :key="index"
      :position="(notification.msg.length-1 - index)*75 + 10"
      :title="noti.title"
      :message="noti.msg"
      :type="noti.type"/>
    <Popup class="message-popup" v-show="popup.show" ref="popup" :loading="load" @close="$store.commit('control/setPopup', {show:false, messages: []})">
      <div v-for="message in popup.messages" :key="message" :class="popup.messages[0].toLowerCase()">
        {{ $te(`error.code.${message}`) ? $t(`error.code.${message}`) : message }} 
      </div>
    </Popup>
    <Spinner :load="load"/>
    <div id="top">
      <NavBar/>
    </div>
    <div id="main">
      <div id="nav" :loading="!ready">
        <!-- <router-link v-for="page in Object.keys($setting.crud)" :key="page" :to="page">{{ page }}</router-link> |       -->
        <Sidebar ref="sidebar"/>
      </div>
      <div id="content-wrapper" :loading="!ready || load">
        <div id="content">
          <router-view :key="$route.fullPath" v-if="ready"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/layouts/NavBar";
import Sidebar from "@/components/layouts/Sidebar";
import Notification from "@/components/widgets/Notification";
import { mapState } from 'vuex';

export default {
  components: {
    NavBar,
    Sidebar,
    Notification
  },
  watch: {
    "popup.show": {
      handler(val) {
        console.log("show: ",val);
        if (val) {
          this.$refs.popup.show();
        } else {
          this.$refs.popup.close();
        }
      },
    },
  },
  methods: {
    logout() {
      this.$confirm(this.$t("message.logout_warning"), this.$t("label.logout"), {
        confirmButtonText: this.$t("label.logout"),
        cancelButtonText: this.$t("button.cancel"),
        type: 'warning'
      }).then(async () => {
        await this.$axios.post(`logout`).catch(err=>err);
        localStorage.removeItem("lt");
        this.$axios.defaults.headers.common["Authorization"] = "";
        this.go("login");
      });
    },
    toggleNav() {
      // console.log("toggle in default layout");
      // this.$refs.sidebar.toggleNav();
      this.$store.commit("control/setSidebar", !this.sidebar);
    }
  },
  computed: {
    ...mapState({
      username: state => state.user.user_info.name,
      roles: state => state.user.user_info.roles,
      sidebar: state => state.control.sidebar,
      ready: state => state.status.ready,
      load: state => state.control.load,
      popup: state => state.control.popup,
      notification: state => state.notify.notification,
    }),
    usr_max_role() {
      let role = {};
      this.roles.map(r=>{
        if(typeof role.level != "undefined") {
          if(r.level > role.level) {
            role = r;
          }
        }else{
          role = r;
        }
      });
      return role;
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss">
.layout--default{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .message-popup{
    .popup-content{
      width: 450px;
      max-width: 95%;
      background: white;
      height: 100px;
      place-items: center
    }
  }
  #top{
    
  }
  #main{
    display: flex;
    flex-grow: 1;
    #content-wrapper{
      // overflow: auto;
      flex-grow: 1;
      // border: 1px solid  var(--layout-border-color);
      display: flex;
      flex-direction: column;
      position: relative;
      .timebar{
        background-color: whitesmoke;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 10px;
        .date{
          font-size: 14px;
        }
      }
      &>#content{
        display: flex;
        margin: 10px;
        // padding: 10px;
        // flex-grow: 1;
        // overflow-y: auto;
      }
    }
  }
}
</style>
